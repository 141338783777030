import { Chat } from 'api/chat'
import { User } from 'api/user'
import { IconDelete, IconEdit, IconEmoji, IconMore, IconReply } from 'icons'
import { FC, ComponentProps } from 'react'
import { DropdownMenu, IconButton } from 'ui'
import { cn } from 'utils'
import styles from './chat-message-actions.module.scss'
import { ChatMessageReactionIcon } from './chat-message-reaction-icon'
import { ChatTooltip } from '../util/chat-tooltip'

interface Props extends ComponentProps<'div'> {
  message: Chat.Message
  onAction?: (action: Chat.MessageAction) => void
  currentUser: User.Id
}
export const ChatMessageActions: FC<Props> = ({
  children,
  className,
  message,
  onAction,
  currentUser,
  ...props
}) => {
  const byCurrentUser = message.user_id === currentUser.user_id
  return (
    <div
      {...props}
      className={cn(styles.actions, byCurrentUser ? styles.my : styles.their, className)}
    >
      <IconButton
        data-tooltip-content="Reply"
        data-tooltip-id={ChatTooltip.ID}
        onClick={() => onAction?.({ message, type: Chat.MessageActionType.Reply })}
        className={styles.button}
      >
        <IconReply />
      </IconButton>

      <DropdownMenu
        attach="right"
        theme="iconButton"
        icon={<IconEmoji />}
        size="small"
        className={styles.menuWrapper}
        menuClassName={styles.emojies}
      >
        {Chat.ReactionsList.slice(0, 3).map((reaction) => (
          <IconButton
            key={reaction}
            data-tooltip-content={Chat.ReactionLabels[reaction]}
            data-tooltip-id={ChatTooltip.ID}
            onClick={() =>
              onAction?.({
                message,
                type: Chat.hasUserReaction(message, currentUser, reaction)
                  ? Chat.MessageActionType.ReactDelete
                  : Chat.MessageActionType.React,
                reaction,
              })
            }
            className={styles.button}
          >
            <ChatMessageReactionIcon type={reaction} />
          </IconButton>
        ))}
      </DropdownMenu>

      {byCurrentUser && (
        <DropdownMenu
          attach="right"
          theme="iconButton"
          icon={<IconMore />}
          size="small"
          className={styles.menuWrapper}
          menuClassName={styles.menu}
          data-tooltip-content="Edit"
          data-tooltip-id={ChatTooltip.ID}
        >
          <DropdownMenu.Button
            icon={<IconEdit />}
            onClick={() => onAction?.({ message, type: Chat.MessageActionType.Edit })}
            title="Edit"
          />
          <DropdownMenu.Button
            icon={<IconDelete />}
            onClick={() => onAction?.({ message, type: Chat.MessageActionType.Delete })}
            title="Delete"
            theme="warn"
          />
        </DropdownMenu>
      )}
    </div>
  )
}
