import { Chat } from 'api/chat'
import { User } from 'api/user'
import { UserFile } from 'api/user-file'
import { FC, ComponentProps } from 'react'
import { To } from 'react-router-dom'
import { Avatar } from 'ui'
import { cn } from 'utils'
import { ChatMessageActions } from './chat-message-actions'
import { ChatMessageAttachment } from './chat-message-attachment'
import { ChatMessageReactions } from './chat-message-reactions'
import { ChatMessageReply, scrollToMessage, toId } from './chat-message-reply'
import styles from './chat-message.module.scss'
import { ChatMessageTime } from '../util/chat-message-time'

interface Props extends ComponentProps<'div'> {
  currentUser: User.Id
  firstInGroup?: boolean
  highlighted?: boolean
  isByCurrentUser: (msg: { user_id: string | null }) => boolean
  lastInGroup?: boolean
  message: Chat.Message
  namesMap: Record<string, string | undefined>
  onAction?: (action: Chat.MessageAction) => void
  onAttachmentClick?: (userFile: UserFile) => void
  onNavigateTo?: (message: Chat.MessageId) => void
  showMyAvatar?: boolean
  showMyName?: boolean
  showTheirAvatar?: boolean
  showTheirName?: boolean
  userFileHref?: (q: UserFile.Id & User.Id) => To
}
export const ChatMessage: FC<Props> = ({
  children,
  className,
  currentUser,
  firstInGroup,
  highlighted,
  isByCurrentUser,
  lastInGroup,
  message,
  namesMap,
  onAction,
  showMyAvatar,
  showMyName,
  showTheirAvatar,
  showTheirName,
  userFileHref,
  ...props
}) => {
  if (!Chat.isMessageVisible(message)) return null
  const my = isByCurrentUser(message)
  const isDeleted = Chat.isMessageDeleted(message)
  const isEdited = Chat.isMessageEdited(message)
  const showAvatar = my ? !!showMyAvatar : !!showTheirAvatar
  const showName = my ? !!showMyName : !!showTheirName
  const name = message.user_id ? namesMap?.[message.user_id] : Chat.NULL_USER_NAME
  return (
    <div
      {...props}
      className={cn(
        styles.message,
        showName && styles.withName,
        showAvatar && styles.withAvatar,
        firstInGroup && styles.first,
        lastInGroup && styles.last,
        !firstInGroup && !lastInGroup && styles.following,
        my ? styles.my : styles.their,
        isDeleted && styles.deleted,
        className,
      )}
      data-id={toId(message)}
    >
      {showAvatar && (
        <div className={styles.user}>
          <Avatar src={null} className={styles.avatar} />
        </div>
      )}
      <ChatMessageTime value={message.created_at} className={styles.time} />
      {showName && name && <span className={styles.name}>{name}</span>}

      {!isDeleted && message.reply_to && (
        <ChatMessageReply
          message={message}
          onClick={scrollToMessage}
          className={styles.reply}
          my={isByCurrentUser(message.reply_to)}
          name={
            !message.reply_to.user_id
              ? undefined
              : showTheirName && message.reply_to.user_id !== currentUser.user_id
              ? namesMap[message.reply_to.user_id]
              : showMyName && message.reply_to.user_id === currentUser.user_id
              ? namesMap[message.reply_to.user_id]
              : undefined
          }
        />
      )}

      <div className={styles.content}>
        {isEdited && (
          <span className={styles.edited}>
            edited <ChatMessageTime value={message.edited_at} />
          </span>
        )}
        {isDeleted ? (
          <span className={cn(styles.text, styles.deletedText)}>deleted</span>
        ) : (
          <span className={styles.text}>{message.message}</span>
        )}

        {!isDeleted && message.file && (
          <ChatMessageAttachment
            file={message.file}
            linkTo={userFileHref?.(message.file)}
            onAction={onAction}
            className={styles.attachment}
          />
        )}

        {!isDeleted && (
          <ChatMessageActions
            message={message}
            onAction={onAction}
            className={styles.actions}
            currentUser={currentUser}
          />
        )}
        {!isDeleted && (
          <ChatMessageReactions
            message={message}
            onAction={onAction}
            currentUser={currentUser}
            className={styles.reactions}
          />
        )}
      </div>
    </div>
  )
}
