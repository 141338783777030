import { Chat, chat as chatApi } from 'api/chat'
import { User } from 'api/user'
import { FC, ComponentProps, useCallback } from 'react'
import { AutoButton } from 'ui/auto-button'
import { cn } from 'utils'
import styles from './auto-mark-read.module.scss'
import { useChatCoreContext } from '../core/core-context'

interface Props extends Omit<ComponentProps<typeof AutoButton>, 'onClick' | 'disabled'> {
  currentUser: User.Brief
}

export const AutoMarkRead: FC<Props> = ({ currentUser, className, ...props }) => {
  const { last_message_read, messages, chat } = useChatCoreContext()

  const isUserInChat = Chat.isUserEnabled(chat, currentUser)
  const lastMessage = messages?.[0]
  const lastMessageId = lastMessage?.message_id
  const isRead = !lastMessageId || lastMessageId === last_message_read
  const disabled = !isUserInChat || isRead || lastMessage?.user_id === currentUser.user_id

  const markAsRead = useCallback(async (event: { currentTarget: HTMLButtonElement }) => {
    if (event.currentTarget.disabled) return
    const { currentUserId, lastMessageId, lastMessageUserId, chatId } = event.currentTarget.dataset
    if (!chatId || !lastMessageId || !currentUserId || lastMessageUserId === currentUserId) return
    try {
      await chatApi.messageRead({ message_id: lastMessageId, chat_id: chatId })
    } catch (error) {
      //ignore
    }
  }, [])

  return (
    <AutoButton
      threshold={1}
      {...props}
      onClick={markAsRead}
      className={cn(styles.read, className)}
      disabled={disabled}
      data-current-user-id={currentUser.user_id}
      data-last-message-id={lastMessage?.message_id}
      data-last-message-user-id={lastMessage?.message_id}
      data-chat-id={chat.chat_id}
    />
  )
}
