import { FC, ComponentProps } from 'react'
import { TimeLocal } from 'ui'

interface Props extends ComponentProps<typeof TimeLocal> {}
export const ChatMessageTime: FC<Props> = ({ children, value, className, ...props }) => {
  return !value ? null : (
    <span className={className}>
      <TimeLocal {...props} value={value} timeStyle="short" />
    </span>
  )
}
