import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

type State<Item> = {
  items: Item[]
  searching: boolean
  done: boolean
  count: number
  error?: string
}

export function useAsyncGeneratorLoader<Item>(
  factory: (config?: {
    signal?: AbortSignal
  }) => AsyncGenerator<{ items: Item[]; count: number }, { items: Item[]; count: number }>,
  itemHash = (item: Item) => JSON.stringify(item),
): [
  state: State<Item>,
  next: () => Promise<Item[] | undefined>,
  setState: Dispatch<SetStateAction<State<Item>>>,
] {
  const abortControllerRef = useRef(new AbortController())
  const generatorRef = useRef<
    AsyncGenerator<{ items: Item[]; count: number }, { items: Item[]; count: number }>
  >(factory({ signal: abortControllerRef.current.signal }))
  const [state, setState] = useState<State<Item>>({
    items: [] as Item[],
    searching: false,
    done: false,
    count: 0,
  })

  const fetchNext = useCallback(async () => {
    if (!generatorRef.current) return
    setState((state) => ({ ...state, searching: true }))
    try {
      const { value, done } = await generatorRef.current.next()

      setState((state) => {
        const items = state.items?.length ? [...state.items] : []
        if (value?.items?.length) {
          const hashes = new Set(items.map(itemHash))
          value?.items?.forEach((item) => {
            if (!hashes.has(itemHash(item))) items.push(item)
          })
        }
        return {
          ...state,
          searching: false,
          done: !!done,
          count: value?.count ?? state.count,
          items,
        }
      })
      return value.items
    } catch (error) {
      setState((state) => ({ ...state, error: (error as Error).message, searching: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const next = useCallback(async () => {
    if (state.done || state.searching) return
    return fetchNext()
  }, [state.done, state.searching, fetchNext])

  useEffect(() => {
    const abortController = abortControllerRef.current
    return () => abortController.abort()
  }, [])

  return [state, next, setState]
}
