import { Chat } from 'api/chat'
import { FC, ComponentProps, MouseEventHandler } from 'react'
import { cn } from 'utils'
import styles from './chat-message-reply.module.scss'

interface Props extends Omit<ComponentProps<'button'>, 'children' | 'type' | 'value'> {
  message: Chat.Message
  my?: boolean
}
export const ChatMessageReply: FC<Props> = ({ className, onClick, message, my, ...props }) => {
  if (!message.reply_to) return null
  return (
    <div className={cn(styles.reply, my ? styles.my : styles.their, className)}>
      <button
        {...props}
        className={styles.button}
        type="button"
        value={toId(message.reply_to)}
        onClick={onClick}
      >
        {message.reply_to.deleted_at ? (
          <span className={styles.deleted}>deleted</span>
        ) : (
          <span className={styles.text}>{message.reply_to.message}</span>
        )}
      </button>
    </div>
  )
}

export const scrollToMessage: MouseEventHandler<HTMLButtonElement> = (event) => {
  const message_id = event.currentTarget.value
  if (!message_id) return
  const element = event.currentTarget
    .closest('[data-chat-container]')
    ?.querySelector(`*[data-id="${message_id}"]`) as HTMLDivElement | undefined
  if (!element) return
  element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  element.dataset.focus = 'true'
}

export const toId = (message: Chat.MessageId) => message.message_id.slice(0, 8)
