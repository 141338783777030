import { FC, ComponentProps, useCallback } from 'react'
import { pdfjs, Document } from 'react-pdf'
// import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

interface Props extends ComponentProps<typeof Document> {
  onLoadPages?: (pages: PdfPageDefinition[]) => void
  pdf: Blob
}

export const PdfDocument: FC<Props> = ({ children, onLoadPages, pdf, ...props }) => {
  const onDocumentLoadSuccess = useCallback<LoadSuccessCallback>(
    (cfg) => collectPages(cfg).then(onLoadPages),
    [onLoadPages],
  )
  return (
    <Document {...props} file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
      {children}
    </Document>
  )
}

type DocumentProps = ComponentProps<typeof Document>
type LoadSuccessCallback = Required<DocumentProps>['onLoadSuccess']
type LoadedConfig = Parameters<LoadSuccessCallback>[0]

export interface PdfPageDefinition {
  id: number
  width: number
  height: number
}

async function collectPages(cfg: LoadedConfig): Promise<PdfPageDefinition[]> {
  const pdfPages = await Promise.all(
    Array.from({ length: cfg.numPages }, (_, i) => cfg.getPage(i + 1)),
  )
  return pdfPages.map((page, id) => {
    const viewport = page.getViewport()
    const [x1, y1, x2, y2] = viewport.viewBox
    return {
      id,
      height: y2 - y1,
      width: x2 - x1,
    }
  })
}
