import { Chat } from 'api/chat'
import { FC, ComponentProps, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './typing-indicator.module.scss'
import Icon from './typing.svg?react'
import { useChatTypingSubscription } from '../util'

interface Props extends Omit<ComponentProps<'div'>, 'children'> {
  chat: Chat
  excludeId?: string | string[]
  includeId?: string | string[]
  renderLabel?: (typingUsers: Chat.User[]) => ReactNode
  fallback?: ReactNode
}
export const ChatTypingIndicator: FC<Props> = ({
  className,
  chat,
  excludeId,
  includeId,
  renderLabel = defaultRenderLabel,
  fallback,
  ...props
}) => {
  const typingUsers = useChatTypingSubscription({
    chat,
    excludeIds: typeof excludeId === 'string' ? [excludeId] : excludeId,
    includeIds: typeof includeId === 'string' ? [includeId] : includeId,
  })
  const isTyping = !!typingUsers?.length

  return (
    <div {...props} className={cn(styles.indicator, className)}>
      {isTyping ? (
        <>
          <span className={styles.label}>{renderLabel(typingUsers)}</span>
          <Icon className={styles.icon} />
        </>
      ) : (
        fallback
      )}
    </div>
  )
}

function defaultRenderLabel() {
  return 'Typing'
}
