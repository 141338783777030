import {
  FC,
  ComponentProps,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react'

interface Props extends ComponentProps<'section'> {
  onContainerReady?: (element: HTMLDivElement) => void
}
export const GroundSection: FC<Props> = ({ children, onContainerReady, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const heightRef = useRef(0)
  const [ready, setReady] = useState(false)

  const updateScroll = useCallback(() => {
    const element = ref?.current
    if (!element) return
    const newHeight = element.scrollHeight
    const offset = newHeight - heightRef.current
    if (heightRef.current !== newHeight) {
      element.scrollBy({ top: offset, behavior: 'instant' as ScrollBehavior })
      heightRef.current = newHeight
    }
  }, [])

  useLayoutEffect(() => {
    const element = ref?.current
    if (!element) return

    // ... scroll to the bottom (first time only)
    element.scrollTo({
      top: element.scrollHeight - element.clientHeight,
      behavior: 'instant' as ScrollBehavior,
    })

    const observer = new MutationObserver((entries) => {
      if (entries.some((entry) => entry.target instanceof HTMLElement && entry.target === element))
        updateScroll()
    })
    element && observer.observe(element, { childList: true })

    setReady(true)
    onContainerReady?.(element)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const element = ref?.current
    if (!element) return
    element.addEventListener('load', updateScroll, { capture: true })
    return () => element.removeEventListener('load', updateScroll)
  }, [updateScroll])

  return (
    <section {...props} ref={ref}>
      {ready ? children : null}
    </section>
  )
}
