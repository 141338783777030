import { Chat, chat as apiChat } from 'api/chat'
import { ChatEvent, ChatMessageEvent, ChatUserEvent, useChatSubscription } from 'api-hooks'
import { useRef } from 'react'

export const useChatStateSubscription = (chat_id: string, cb: (chat: Chat) => void) => {
  const abortControllerRef = useRef<AbortController>()

  useChatSubscription(chat_id, (message) => {
    switch (message.name) {
      case ChatEvent.MSG_NEW:
      case ChatEvent.MSG_READ:
      case ChatEvent.USED_ADD:
        const { chat_id } = (message.data as ChatMessageEvent | ChatUserEvent) ?? {}
        if (!chat_id) return

        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()
        apiChat
          .byId(chat_id, { signal: abortControllerRef.current.signal })
          .then((chat) => {
            chat && cb(chat)
          })
          .catch(() => null /* ignore */)
        break
    }
  })
}
