import { Chat, chat as apiChat } from 'api/chat'
import { ChatEvent, ChatMessageEvent, useChatSubscription } from 'api-hooks'

export const useChatMessageSubscription = (
  chat_id: string,
  cb: (message: Chat.Message) => void,
) => {
  useChatSubscription(chat_id, (message) => {
    switch (message.name) {
      case ChatEvent.MSG_NEW:
      case ChatEvent.MSG_UPD:
      case ChatEvent.MSG_DEL:
      case ChatEvent.REACT_NEW:
      case ChatEvent.REACT_DEL:
        const { message_id } = (message.data as ChatMessageEvent) ?? {}
        if (!message_id) return
        apiChat
          .messageById(message_id)
          .then((message) => {
            message && cb(message)
          })
          .catch(() => null /* ignore */)
        break
    }
  })
}
