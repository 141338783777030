import { Chat } from 'api/chat'
import { User } from 'api/user'
import { UserFile } from 'api/user-file'
import { FC, ComponentProps, useEffect, useState } from 'react'
import { To } from 'react-router-dom'
import { AutoButton } from 'ui/auto-button'
import { cn } from 'utils'
import styles from './chat-messages.module.scss'
import { GroundSection } from './ground-section'
import { AutoMarkRead } from '../auto-mark-read'
import { useChatCoreContext } from '../core/core-context'
import { ChatMessage } from '../message'
import { MessagesDay } from '../messages-day'

interface Props extends Omit<ComponentProps<'section'>, 'children'> {
  currentUser: User.Brief
  userFileHref?: (q: UserFile.Id) => To
  lastMessageRead?: string
  showAvatar?: 'my' | 'their' | 'all'
  showName?: 'my' | 'their' | 'all'
  isByCurrentUser?: (msg: { user_id: string | null }) => boolean
}

export const ChatMessages: FC<Props> = ({
  currentUser,
  className,
  userFileHref,
  lastMessageRead,
  showAvatar,
  showName,
  isByCurrentUser = ({ user_id }) => currentUser.user_id === user_id,
  ...props
}) => {
  const { searching, next, done, error, messages, contextAction, handleAction, userNameMap } =
    useChatCoreContext()

  const [containerElement, setContainerElement] = useState<HTMLDivElement | undefined>()
  const selectedMessage = contextAction?.message
  const isSelected = selectedMessage ? Chat.byMessageId(selectedMessage.message_id) : () => false
  const isLastRead = lastMessageRead ? Chat.byMessageId(lastMessageRead) : () => false

  const includesLastRead = !!lastMessageRead && messages.some(isLastRead)

  useEffect(() => {
    if (containerElement && includesLastRead) {
      containerElement.querySelector(`[data-last-read]`)?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [containerElement, includesLastRead])

  const showMyAvatar = showAvatar && ['all', 'my'].includes(showAvatar)
  const showTheirAvatar = showAvatar && ['all', 'their'].includes(showAvatar)
  const showMyName = showName && ['all', 'my'].includes(showName)
  const showTheirName = showName && ['all', 'their'].includes(showName)

  return (
    <div className={cn(styles.wrapper, className)}>
      <GroundSection
        {...props}
        className={cn(
          styles.section,
          showMyAvatar && styles.withMyAvatar,
          showTheirAvatar && styles.withTheirAvatar,
        )}
        data-chat-container
        onContainerReady={setContainerElement}
      >
        <AutoButton
          disabled={searching || done || !!error}
          onClick={next}
          className={styles.more}
          threshold={0.925}
        >
          {searching
            ? 'loading'
            : done
            ? 'the beginning of the conversation'
            : error
            ? `An error occured: ${error}`
            : 'more'}
        </AutoButton>

        {[...messages].reverse().map((message, index, messages) => {
          if (!Chat.isMessageVisible(message)) return null
          const selected = isSelected(message)

          const element = (
            <ChatMessage
              className={styles.message}
              currentUser={currentUser}
              data-last-read={isLastRead(message) ? 'true' : undefined}
              data-selected={selected ? 'true' : undefined}
              firstInGroup={!Chat.shouldGroupWithPrev(message, messages[index - 1])}
              highlighted={selected}
              isByCurrentUser={isByCurrentUser}
              key={message.message_id}
              lastInGroup={!Chat.shouldGroupWithNext(message, messages[index + 1])}
              message={message}
              namesMap={userNameMap}
              onAction={handleAction}
              showMyAvatar={showMyAvatar}
              showMyName={showMyName}
              showTheirAvatar={showTheirAvatar}
              showTheirName={showTheirName}
              userFileHref={userFileHref}
            />
          )

          return Chat.isSameDay(message, messages[index - 1])
            ? element
            : [
                <MessagesDay value={message.created_at} key={`${message.message_id}-day`} />,
                element,
              ]
        })}

        <AutoMarkRead currentUser={currentUser} />
      </GroundSection>
    </div>
  )
}
