import { FC, ComponentProps } from 'react'
import { TimeLocal } from 'ui'
import { cn } from 'utils'
import { isSameDayLocal, isSameYearLocal } from 'utils/date'
import styles from './messages-day.module.scss'

interface Props extends ComponentProps<typeof TimeLocal> {}

export const MessagesDay: FC<Props> = ({ children, className: _className, value, ...props }) => {
  const className = cn(styles.day, _className)
  return value && isSameDayLocal(value) ? (
    <span {...props} className={className}>
      Today
    </span>
  ) : value && isSameYearLocal(value) ? (
    <TimeLocal {...props} day="numeric" month="short" className={className} value={value} />
  ) : (
    <TimeLocal {...props} dateStyle="medium" className={className} value={value} />
  )
}
