import { Chat } from 'api/chat'
import { ACCEPT_IMG_DOC } from 'api/user-file'
import { IconAttachment } from 'icons'
import { ComponentProps, FC, useCallback } from 'react'
import { FileField, Tooltip } from 'ui'
import { FieldState } from 'ui/form/form/context'
import { cn } from 'utils'
import styles from './chat-attach-button.module.scss'
import { useChatCoreContext } from '../core/core-context'

interface Props extends Omit<ComponentProps<typeof FileField>, 'children'> {}

export const ChatAttachButton: FC<Props> = ({ className, ...props }) => {
  const { handleAction } = useChatCoreContext()

  const handleChange = useCallback(
    ({ value: file }: FieldState<Record<string, File | null>, any>) => {
      if (!file) return
      handleAction({ type: Chat.MessageActionType.AttachFile, attachment: file })
    },
    [handleAction],
  )
  return (
    <FileField
      {...props}
      accept={ACCEPT_IMG_DOC}
      className={cn(styles.field, className)}
      onChange={handleChange}
    >
      <IconAttachment
        className={styles.icon}
        data-tooltip-id={Tooltip.ID}
        data-tooltip-content="Attach File"
      />
    </FileField>
  )
}
