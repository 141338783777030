import { Chat } from 'api/chat'
import { User } from 'api/user'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import { ChatMessageReaction } from './chat-message-reaction'
import styles from './chat-message-reactions.module.scss'

interface Props extends ComponentProps<'div'> {
  message: Chat.Message
  onAction?: (action: Chat.MessageAction) => void
  currentUser: User.Id
}
export const ChatMessageReactions: FC<Props> = ({
  children,
  className,
  message,
  onAction,
  currentUser,
  ...props
}) => {
  if (!message.reactions?.length) return null
  const userIdByReaction = Object.entries(Chat.groupUserIdsByReaction(message))
  if (!userIdByReaction.length) return null

  return (
    <div {...props} className={cn(styles.reactions, className)}>
      {userIdByReaction.map(([reaction, ids]) => (
        <ChatMessageReaction
          key={reaction}
          className={styles.reaction}
          message={message}
          onAction={onAction}
          currentUser={currentUser}
          type={reaction as Chat.Reaction}
          userIds={ids}
        />
      ))}
    </div>
  )
}
