import { Chat, chat as chatApi } from 'api/chat'
import { useMemo } from 'react'

export const useChatSetTyping = (chat: Chat) => {
  return useMemo(() => {
    const send = () =>
      chatApi.typing(chat.chat_id).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
    let timeout: ReturnType<typeof setTimeout>
    let shouldSend = true

    const resetTimeout = () => {
      clearTimeout(timeout)
      shouldSend = false
      timeout = setTimeout(() => {
        shouldSend = true
      }, Chat.TYPING_TIMEOUT / 2)
    }
    return () => {
      if (shouldSend) {
        send()
        resetTimeout()
      }
    }
  }, [chat.chat_id])
}
