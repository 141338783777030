import { Chat } from 'api/chat'
import { ACCEPT_DOC, ACCEPT_IMG, userFile as apiUserDocument, UserFile } from 'api/user-file'
import { FC, ComponentProps, useRef } from 'react'
import { FileIcon, Link, Loader, Num, useAsyncEffect } from 'ui'
import { cn } from 'utils'
import styles from './chat-message-attachment.module.scss'
import { PdfThumbnail } from '../pdf-viewer'
import { useElementInView } from '../util'

interface Props extends Omit<ComponentProps<'div'>, 'children' | 'type' | 'value'> {
  file: UserFile
  linkTo?: ComponentProps<typeof Link>['to']
  onAction?: (action: Chat.MessageAction) => void
}
export const ChatMessageAttachment: FC<Props> = ({
  className,
  file,
  linkTo,
  onAction,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const shouldLoad = useElementInView(ref.current)
  const [blob, loading] = useAsyncEffect(
    async (config) =>
      shouldLoad ? apiUserDocument.downloadBlobById(file.file_id, config) : undefined,
    [shouldLoad],
  )

  const isImage = blob && ACCEPT_IMG.includes(file.mimetype)
  const isDocument = !isImage && blob && ACCEPT_DOC.includes(file.mimetype)
  const showLoader = loading && (isImage || isDocument)

  const onClick = onAction
    ? () => onAction({ type: Chat.MessageActionType.OpenAttachment, blob, userFile: file })
    : undefined

  const preview = showLoader ? (
    <Loader className={styles.loader} />
  ) : isImage ? (
    <ImagePreview blob={blob} className={styles.img} />
  ) : isDocument ? (
    <PdfPreview blob={blob} className={styles.pdf} loading={<Loader className={styles.loader} />} />
  ) : null

  return (
    <div
      {...props}
      className={cn(styles.attachment, className)}
      ref={ref}
      data-attachment-type={isImage ? 'image' : isDocument ? 'document' : 'other'}
    >
      {preview &&
        (linkTo ? (
          <Link
            unstyled
            to={linkTo}
            preventScrollReset
            className={cn(styles.preview, styles.link)}
            onClick={onClick}
          >
            {preview}
          </Link>
        ) : (
          <div className={styles.preview} onClick={onClick}>
            {preview}
          </div>
        ))}
      {!isImage && (
        <div className={styles.info}>
          <FileIcon className={styles.icon} type={file.mimetype} />
          {linkTo ? (
            <Link className={styles.name} to={linkTo} onClick={onClick}>
              {file.filename}
            </Link>
          ) : (
            <div className={styles.name} onClick={onClick}>
              {file.filename}
            </div>
          )}
          <Num className={styles.size} value={file.size} unit="kilobyte" />
        </div>
      )}
    </div>
  )
}

const ImagePreview = ({ blob, className }: { blob: Blob; className: string }) => {
  return <img src={URL.createObjectURL(blob)} className={className} alt="preview" />
}

const PdfPreview = ({
  blob,
  ...props
}: Omit<ComponentProps<typeof PdfThumbnail>, 'pdf'> & { blob: Blob }) => {
  return <PdfThumbnail {...props} pdf={blob} />
}
