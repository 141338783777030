import { Chat, chat as apiChat } from 'api/chat'
import { User } from 'api/user'
import { useState, useCallback, useEffect } from 'react'
import { alertErrorMessage } from 'utils/toast'
import {
  useAsyncGeneratorLoader,
  useChatMessageSubscription,
  useChatStateSubscription,
} from '../util'

export type NameFormatter = (
  user: Chat.User | undefined,
  currentUser: User.Brief,
  chat: Chat,
) => string | undefined
interface Options {
  chat: Chat
  currentUser: User.Brief
  pageSize?: number
  nameFormatter?: NameFormatter
  onAttachmentClick?: (data: Chat.MessageActionOpenAttachment) => void
}
export function useChatCore({
  chat: _chat,
  currentUser,
  pageSize = 10,
  nameFormatter = defaultNameFormatter,
  onAttachmentClick,
}: Options) {
  const [chat, setChat] = useState(_chat)
  useChatStateSubscription(chat.chat_id, setChat)

  const [{ items: messages, searching, done, error }, next, setState] = useAsyncGeneratorLoader(
    (config) =>
      apiChat.messagePaginatedList(
        {
          filter: { chat_id: [chat.chat_id] },
          order: [{ name: 'created_at', desc: true }],
          pagination: { page_size: pageSize, page: 1 },
        },
        config,
      ),
    Chat.pickMessageId,
  )
  useChatMessageSubscription(chat.chat_id, (message) => {
    setState((state) => {
      const index = state.items.findIndex(Chat.byMessageId(message.message_id))
      if (index !== -1) {
        const items = [...state.items]
        items.splice(index, 1, message)
        return { ...state, items }
      }
      return {
        ...state,
        count: state.count + 1,
        items: [message, ...state.items],
      }
    })
  })
  const [contextAction, setContextAction] = useState<
    Chat.MessageActionEdit | Chat.MessageActionReply | Chat.MessageActionAttachFile | undefined
  >()
  const handleAction = useCallback(
    async (action: Chat.MessageAction) => {
      try {
        switch (action.type) {
          case Chat.MessageActionType.React:
            await apiChat.messageReact({
              message_id: action.message.message_id,
              reaction: action.reaction,
              chat_id: chat.chat_id,
            })
            break
          case Chat.MessageActionType.ReactDelete:
            await apiChat.messageDeleteReaction({
              message_id: action.message.message_id,
              reaction: action.reaction,
              chat_id: chat.chat_id,
            })
            break
          case Chat.MessageActionType.Reply:
          case Chat.MessageActionType.Edit:
            setContextAction(action)
            break
          case Chat.MessageActionType.Delete:
            await apiChat.messageDelete({
              chat_id: chat.chat_id,
              message_id: action.message.message_id,
            })
            break
          case Chat.MessageActionType.ClearContext:
            setContextAction(undefined)
            break
          case Chat.MessageActionType.OpenAttachment:
            onAttachmentClick?.(action)
            break
          case Chat.MessageActionType.Send:
            return await apiChat
              .messageCreate({
                ...action.data,
                ...(action.attachment && { file: action.attachment }),
              })
              .then((result) => {
                setContextAction(undefined)
                return result
              })
          case Chat.MessageActionType.Update:
            return await apiChat
              .messageUpdate({
                ...action.data,
                ...(action.attachment && { file: action.attachment }),
              })
              .then((result) => {
                setContextAction(undefined)
                return result
              })
          case Chat.MessageActionType.AttachFile:
            if (action.attachment instanceof File) {
              setContextAction((prevAction) =>
                prevAction ? { ...prevAction, attachment: action.attachment } : action,
              )
            }
            break
          case Chat.MessageActionType.ClearAttachment:
            setContextAction((prevAction) => {
              if (!prevAction || prevAction.type === Chat.MessageActionType.AttachFile)
                return undefined
              const { attachment, ...rest } = prevAction
              return rest
            })
            break
        }
      } catch (e) {
        alertErrorMessage((e as Error)?.message)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chat.chat_id],
  )

  const userNameMap = Object.fromEntries(
    chat.users?.map((chatUser) => [chatUser.user_id, nameFormatter(chatUser, currentUser, chat)]) ??
      [],
  )
  const last_message_read = Chat.getLastReadMessageForUser(chat, currentUser)
  const shouldCheckMissingMessages = !searching && !done && !error

  useEffect(() => {
    if (!shouldCheckMissingMessages) return
    if (!last_message_read || Chat.hasMissingReference(messages, [last_message_read])) {
      next()
    }
  }, [last_message_read, messages, next, shouldCheckMissingMessages])

  return {
    messages,
    searching,
    done,
    error,
    handleAction,
    contextAction,
    chat,
    userNameMap,
    next,
    /** `message.message_id` last message read by user */
    last_message_read,
  }
}

const defaultNameFormatter: NameFormatter = (chatUser, currentUser) =>
  chatUser?.user_id === currentUser.user_id ? 'You' : chatUser?.user.first_name ?? undefined
