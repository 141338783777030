import { Chat } from 'api/chat'
import { User } from 'api/user'
import { ChatEvent, ChatMessageEvent, ChatUserEvent, useChatSubscription } from 'api-hooks'
import { useState } from 'react'

type Timeout = ReturnType<typeof setTimeout>

const createUpdater =
  (user_id: string, value: Timeout | undefined) =>
  (users: Record<string, Timeout | undefined>): Record<string, Timeout | undefined> => {
    if (users[user_id]) clearTimeout(users[user_id])
    return { ...users, [user_id]: value }
  }

export const useChatTypingSubscription = ({
  chat,
  includeIds,
  excludeIds,
}: {
  chat: Chat
  excludeIds?: string[]
  includeIds?: string[]
}): Chat.User[] => {
  const [typingUsers, setTypingUsers] = useState<Record<string, Timeout | undefined>>({})
  useChatSubscription(chat.chat_id, (event) => {
    // when a message is sent, updated, or deleted, clear the typing indicator
    if ([ChatEvent.MSG_NEW, ChatEvent.MSG_UPD, ChatEvent.MSG_DEL].includes(event.name)) {
      const user_id = (event.data as ChatMessageEvent)?.user_id
      if (user_id) setTypingUsers(createUpdater(user_id, undefined))
      return
    }
    // otherwise, if the event is not a typing event, ignore it
    if (event.name !== ChatEvent.TYPING) return

    const { chat_id, user_id } = (event.data as ChatUserEvent) ?? {}
    if (chat_id !== chat.chat_id) return
    if (includeIds && !includeIds.includes(user_id)) return
    if (excludeIds?.includes(user_id)) return

    const clear = setTimeout(() => {
      setTypingUsers(createUpdater(user_id, undefined))
    }, Chat.TYPING_TIMEOUT)

    setTypingUsers(createUpdater(user_id, clear))
  })
  const users = Object.entries(typingUsers)
    .map(([user_id, timeout]) => (timeout ? chat.users?.find(User.byId(user_id)) : null))
    .filter(Boolean) as Chat.User[]

  return users
}
