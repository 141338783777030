import { FC, ComponentProps, useState } from 'react'
import { Document } from 'react-pdf'
import { PdfDocument, PdfPageDefinition } from './pdf-document'
import { PdfPage } from './pdf-page'
import styles from './pdf-thumbnail.module.scss'

interface Props extends Omit<ComponentProps<typeof Document>, 'onLoadPages' | 'children'> {
  pdf: Blob
}

export const PdfThumbnail: FC<Props> = ({ pdf, ...props }) => {
  const [pages, setPages] = useState<PdfPageDefinition[]>()
  const page = pages?.[0]
  return (
    <PdfDocument {...props} pdf={pdf} onLoadPages={setPages}>
      {page && (
        <PdfPage
          id={page.id}
          pageHeight={page.height}
          pageWidth={page.width}
          className={styles.page}
        />
      )}
    </PdfDocument>
  )
}
