import { AdminUser, User } from 'api/admin'
import { IconBack, IconClose, IconMore } from 'icons'
import { FC, ComponentProps, ReactNode } from 'react'
import { To } from 'react-router-dom'
import { Avatar, DropdownMenu, IconButton, Link, RelativeTime } from 'ui'
import { cn } from 'utils'
import { getFullNameOrEmail, getInitials } from 'utils/full-name'
import styles from './admin-support-header.module.scss'
import { useChatCoreContext } from '../core/core-context'
import { ChatTypingIndicator } from '../typing-indicator'

interface Props extends Omit<ComponentProps<'header'>, 'children'> {
  onBack?: () => void
  onClose?: () => void
  menuItems?: ReactNode
  userHref?: (q: User.Id) => To
  reporter?: AdminUser
}

export const ChatAdminSupportHeader: FC<Props> = ({
  className,
  onBack,
  onClose,
  menuItems,
  userHref,
  reporter,
  ...props
}) => {
  const { chat } = useChatCoreContext()
  const link = reporter && userHref ? userHref(reporter) : undefined
  const name = getFullNameOrEmail(reporter) ?? 'Deleted user'

  return (
    <header {...props} className={cn(styles.header, className)}>
      {onBack && (
        <IconButton className={styles.back} onClick={onBack}>
          <IconBack />
        </IconButton>
      )}
      <Avatar className={styles.avatar} initials={getInitials(reporter)} />

      <div className={styles.main}>
        {link ? (
          <Link to={link} className={styles.name}>
            {name}
          </Link>
        ) : (
          <span className={styles.name}>{name}</span>
        )}

        <ChatTypingIndicator
          key={chat.chat_id}
          chat={chat}
          includeId={reporter?.user_id}
          className={styles.typing}
          fallback={
            <span className={styles.activity}>
              Last activity:{' '}
              <RelativeTime value={reporter?.last_activity_at} formatStyle="narrow" />
            </span>
          }
        />
      </div>

      {menuItems && (
        <DropdownMenu
          attach="right"
          icon={<IconMore />}
          className={styles.menu}
          theme="flat"
          size="small"
        >
          {menuItems}
        </DropdownMenu>
      )}
      {onClose && (
        <IconButton className={styles.close} onClick={onClose}>
          <IconClose />
        </IconButton>
      )}
    </header>
  )
}
