import { useState, useEffect } from 'react'

export function useElementInView(
  element: HTMLElement | null,
  init: IntersectionObserverInit = { threshold: 0.1 },
) {
  const [isInView, setIsInView] = useState(false)
  useEffect(() => {
    if (!element || element.ariaDisabled) return
    const observer = new IntersectionObserver((entries) => {
      if (!entries.some(isIntersecting)) return
      setIsInView(true)
      observer.disconnect()
    }, init)
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element])

  return isInView
}

function isIntersecting(entry: IntersectionObserverEntry) {
  const target = entry.target as HTMLButtonElement
  return entry.isIntersecting && !(target.disabled || target.ariaDisabled)
}
