import { Chat } from 'api/chat'
import { FC, ComponentProps } from 'react'

interface Props extends Omit<ComponentProps<'span'>, 'children'> {
  type: Chat.Reaction
}
export const ChatMessageReactionIcon: FC<Props> = ({ type, ...props }) => {
  return <span {...props}>{ICONS[type]}</span>
}

const ICONS = {
  [Chat.Reaction.ANGRY]: Chat.Reaction.ANGRY,
  [Chat.Reaction.CHEERS]: Chat.Reaction.CHEERS,
  [Chat.Reaction.COMPLETE]: Chat.Reaction.COMPLETE,
  [Chat.Reaction.HEART]: Chat.Reaction.HEART,
  [Chat.Reaction.LAUGH]: Chat.Reaction.LAUGH,
  [Chat.Reaction.SAD]: Chat.Reaction.SAD,
  [Chat.Reaction.THUMBS_UP]: Chat.Reaction.THUMBS_UP,
  [Chat.Reaction.WAVE]: Chat.Reaction.WAVE,
}
