import { Chat } from 'api/chat'
import { FC, ComponentProps, useState, DragEventHandler, useCallback } from 'react'
import { cn } from 'utils'
import styles from './dropzone.module.scss'
import { useChatCoreContext } from '../core/core-context'

interface Props extends ComponentProps<'div'> {
  disabled?: boolean
}

export const ChatDropzone: FC<Props> = ({ children, className, disabled, ...props }) => {
  const { handleAction } = useChatCoreContext()
  const [dragging, setDradding] = useState(false)

  const attachFile = useCallback(
    (attachment: File) => handleAction({ type: Chat.MessageActionType.AttachFile, attachment }),
    [handleAction],
  )

  const handleDragEnter = useCallback<DragEventHandler>((event) => {
    if (event.dataTransfer?.items?.length !== 1) return
    const file = event.dataTransfer.items[0]
    if (!file) return
    if (!Chat.isFileTypeAccepted(file.type)) return
    event.preventDefault()
    setDradding(true)
  }, [])

  const handleDragExit = useCallback<DragEventHandler>(() => setDradding(false), [])

  const handleDrop = useCallback<DragEventHandler>(
    (event) => {
      event.preventDefault()
      setDradding(false)
      if (event.dataTransfer?.items?.length !== 1) return
      const file = event.dataTransfer.files[0]
      if (!file || !Chat.isFileTypeAccepted(file.type)) return
      attachFile(file)
    },
    [attachFile],
  )

  return (
    <div
      {...props}
      className={cn(styles.dropzone, className)}
      {...(disabled
        ? {}
        : {
            onDragEnter: handleDragEnter,
            onDragOver: handleDragEnter,
            onDragLeave: handleDragExit,
            onDrop: handleDrop,
            ...(dragging && { 'data-drag-over': true }),
          })}
    >
      {children}
    </div>
  )
}
