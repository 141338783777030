import { FC, ComponentProps } from 'react'
import { Tooltip } from 'ui'
import styles from './chat-tooltip.module.scss'

interface Props extends ComponentProps<typeof Tooltip> {}

type Type = FC<Props> & { ID: string }

export const CHAT_TOOLTIP_ID = styles.tooltip

export const ChatTooltip: Type = (({ children, ...props }) => {
  return (
    <Tooltip {...props} id={CHAT_TOOLTIP_ID} delayShow={300}>
      {children}
    </Tooltip>
  )
}) as Type

ChatTooltip.ID = CHAT_TOOLTIP_ID
