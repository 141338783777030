import { Chat } from 'api/chat'
import { MimeType } from 'api/user-file'
import { IconClose } from 'icons'
import { FC, ComponentProps, useCallback } from 'react'
import { Card, FileIcon, FileName, IconButton, Loader, Num, useFormProps } from 'ui'
import { cn } from 'utils'
import styles from './chat-composer-attachment.module.scss'
import { useChatCoreContext } from '../core/core-context'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {
  // file: File | Blob
  // onRemove?: () => any
}
export const ChatComposerAttachment: FC<Props> = ({ className, ...props }) => {
  const { pending } = useFormProps()

  const { contextAction, handleAction } = useChatCoreContext()
  const removeAttachment = useCallback(
    () => handleAction({ type: Chat.MessageActionType.ClearAttachment }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const file = contextAction?.attachment
  if (!file) return null

  return (
    <div {...props} className={cn(styles.attachment, className)}>
      {pending ? (
        <Loader className={styles.loader} />
      ) : (
        <FileIcon className={styles.icon} type={file.type as MimeType} />
      )}
      <FileName filename={file.name} mimetype={file.type} className={styles.name} />
      <Num className={styles.size} value={file.size} unit="kilobyte" />
      <IconButton className={styles.remove} onClick={removeAttachment}>
        <IconClose className={styles.delIcon} />
      </IconButton>
    </div>
  )
}
