import { Chat } from 'api/chat'
import { User } from 'api/user'
import { FC, ComponentProps } from 'react'
import { Button } from 'ui'
import { cn } from 'utils'
import { ChatMessageReactionIcon } from './chat-message-reaction-icon'
import styles from './chat-message-reaction.module.scss'
import { useChatCoreContext } from '../core/core-context'
import { ChatTooltip } from '../util'

interface Props
  extends Omit<ComponentProps<'button'>, 'onClick' | 'onAction' | 'children' | 'type' | 'ref'> {
  message: Chat.Message
  onAction?: (action: Chat.MessageAction) => void
  userIds: string[]
  type: Chat.Reaction
  currentUser: User.Id
}

export const ChatMessageReaction: FC<Props> = ({
  className,
  message,
  onAction,
  currentUser,
  type,
  userIds,
  ...props
}) => {
  const { userNameMap } = useChatCoreContext()
  if (!userIds.length) return null

  const names = new Set<string>()
  userIds.forEach((user_id) => {
    const name = userNameMap[user_id]
    name && names.add(name)
  })
  const reacted = userIds.includes(currentUser.user_id)

  return (
    <Button
      {...props}
      className={cn(styles.reaction, reacted && styles.reacted, className)}
      data-tooltip-content={Array.from(names).join('\n')}
      data-tooltip-id={ChatTooltip.ID}
      onClick={() => {
        onAction?.({
          type: reacted ? Chat.MessageActionType.ReactDelete : Chat.MessageActionType.React,
          message,
          reaction: type,
        })
      }}
    >
      <ChatMessageReactionIcon type={type} />
      {names.size > 1 && <span className={styles.amount}>{names.size}</span>}
    </Button>
  )
}
