import { Chat } from 'api/chat'
import { useChatSubscription } from 'api-hooks'
import { FC } from 'react'

interface Props {
  chat: Chat.Id
  onMessage: Parameters<typeof useChatSubscription>[1]
}
export const ChatSubscription: FC<Props> = ({ chat, onMessage }) => {
  useChatSubscription(chat.chat_id, onMessage)
  return null
}
