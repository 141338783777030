import { createContext, useContext } from 'react'
import { useChatCore } from './use-chat-core'

type ChatCoreContextValue = ReturnType<typeof useChatCore>

export const ChatCoreContext = createContext<ChatCoreContextValue | undefined>(undefined)

export const useChatCoreContext = () => {
  const value = useContext(ChatCoreContext)
  if (!value) {
    throw new Error('useChatCoreContext must be used within a ChatCoreContext.Provider')
  }
  return value
}
